import { useHeartBeatPing } from '@adeptlms/lingu-students-react-shared';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { IdleTimerAPI, IdleTimerProps, useIdleTimer } from 'react-idle-timer';
import { DEFAULT_IDLE_TIMER, DEFAULT_PING_TIMER } from 'students/constants';

import { usePageVisibility } from '../../hooks';

export type tHeartBeatChangeOptionsContextValue = {
  updateOptions: (options?: IdleTimerProps) => void;
} | null;

const HeartBeatContext = React.createContext<IdleTimerAPI | null>(null);
const HeartBeatChangeOptionsContext =
  React.createContext<tHeartBeatChangeOptionsContextValue>(null);

export default HeartBeatContext;

interface IProps {
  options?: IdleTimerProps;
}
export const HeartBeatContextProvider: FC<PropsWithChildren<IProps>> = ({
  children,
  options
}) => {
  const [idleOptions, setIdleOptions] = useState<IdleTimerProps>({ stopOnIdle: true });

  const updateOptions = useCallback(function (newOptions?: IdleTimerProps) {
    if (newOptions) {
      setIdleOptions((opt) => ({ ...opt, ...newOptions }));
    }
  }, []);
  const changeOptionsValue = useMemo(() => ({ updateOptions }), [updateOptions]);

  const pingAction = useHeartBeatPing();
  const isDocumentHidden = usePageVisibility();

  const heartBeatApi = useIdleTimer({
    timeout: DEFAULT_IDLE_TIMER,
    ...idleOptions
  });

  useEffect(() => {
    updateOptions(options);
  }, [options, updateOptions]);

  useEffect(() => {
    let timerId = -1;
    function pingTimeoutAction() {
      return window.setTimeout(() => {
        if (heartBeatApi && !heartBeatApi.isIdle() && !isDocumentHidden()) {
          pingAction();
        }
        timerId = pingTimeoutAction();
      }, DEFAULT_PING_TIMER);
    }
    timerId = pingTimeoutAction();

    return () => {
      clearTimeout(timerId);
    };
  }, [pingAction, isDocumentHidden, heartBeatApi]);

  return (
    <HeartBeatContext.Provider value={heartBeatApi}>
      <HeartBeatChangeOptionsContext.Provider value={changeOptionsValue}>
        {children}
      </HeartBeatChangeOptionsContext.Provider>
    </HeartBeatContext.Provider>
  );
};

export function useHeartBeatChangeOptions(options: IdleTimerProps): void {
  const optionsContext = useContext(HeartBeatChangeOptionsContext);

  useEffect(() => {
    if (optionsContext) {
      optionsContext.updateOptions(options);
    }

    return () => {
      if (optionsContext) {
        optionsContext.updateOptions({
          timeout: DEFAULT_IDLE_TIMER
        });
      }
    };
  }, [options, optionsContext]);
}

export function useHeartBeatChangeOptionsContext(): tHeartBeatChangeOptionsContextValue {
  return useContext(HeartBeatChangeOptionsContext);
}
